<template>
  <div id="divLearnWrapper" class="content-layout-fixer">
    <div class="header-wrapper">
      <div v-for="(module, index) in academyModules" :key="index">
        <div v-if="getModuleType() === module.menuName">
          <VueText sizeLevel="9" weightLevel="5" color="grey-50">{{ module.name }}</VueText>
          <div class="level-card-wrapper">
            <BrandInfoCard
              @click="goToAcademy(type)"
              v-for="(type, i) in module.types"
              :key="type.contentTypeId"
              :bg-color="infoCardBgColor(type, i)"
              :sizeLevel="'11'"
              :title="type.contentName"
              class="level-card-wrapper-item"
              :titleMaxWidth="160"
              :academyParentTypeId="module.parentTypeId"
              :infoCardStyles="infoCardBgImage(type)"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="calculator-wrapper" v-if="isPartner">
      <VueText sizeLevel="9" weightLevel="5" color="grey-50" class="calc-header"
        >Hesaplama Araçları</VueText
      >
      <div v-for="item in calcLinkItems" :key="item.path" class="calc-link-items">
        <VueButton
          :size="sizes.xLarge"
          class="button-link"
          color="white"
          :target="item.path"
          as="router-link"
          :outlined="true"
          :rightIcon="icon.right"
        >
          <VueText sizeLevel="5" weightLevel="2">{{ item.name }}</VueText>
        </VueButton>
      </div>
    </div>
  </div>
</template>

<script>
import VueButton from '@/components/shared/VueButton/VueButton.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import { ICON_VARIABLES, COMPONENT_SIZES } from '@/constants/component.constants.js';
import RoutesRoot from '@/router/routes/RoutesRoot.js';
import RoutesSecure from '@/router/routes/RoutesSecure';
import RoutesLearn from '@/router/routes/secure/RoutesLearn';
import BrandInfoCard from '@/components/brand/BrandInfoCard/BrandInfoCard.vue';
import { Learn } from '@/services/Api/index';
import AccountMixin from '@/utils/accountUtils.js';
import StorageProps from '@/mixins/storageProps.js';

export default {
  name: 'Home',
  components: { VueText, VueButton, BrandInfoCard },
  mixins: [AccountMixin, StorageProps],
  data() {
    return {
      academyModules: [
        { name: 'Girişimcilik', parentTypeId: 452, menuName: 'Girişimcilik Akademi', types: [] },
        {
          name: 'Dijitalleşiyorum',
          parentTypeId: 26,
          menuName: 'Dijital Akademi',
          types: [],
        },
      ],
      calcLinkItems: [
        {
          name: 'Yok satarak ne kadar kaybediyorsunuz?',
          path: `${RoutesRoot.Secure.path}/${RoutesSecure.Learn.path}/${RoutesLearn.TotalLoss.path}`,
        },
        {
          name: 'Kârınızı hesaplayın',
          path: `${RoutesRoot.Secure.path}/${RoutesSecure.Learn.path}/${RoutesLearn.Endorsement.path}`,
        },
        {
          name: 'Ciro dağılımınızı öğrenin',
          path: `${RoutesRoot.Secure.path}/${RoutesSecure.Learn.path}/${RoutesLearn.Turnover.path}`,
        },
        {
          name: 'Hangi üründen ne kadar kâr ediyorsunuz?',
          path: `${RoutesRoot.Secure.path}/${RoutesSecure.Learn.path}/${RoutesLearn.EndorsementByCategory.path}`,
        },
      ],
    };
  },
  computed: {
    icon() {
      return {
        right: ICON_VARIABLES['right'],
      };
    },
    sizes() {
      return COMPONENT_SIZES;
    },
  },
  async created() {
    await this.getLearnContentTypes();
  },
  methods: {
    getModuleType() {
      return this.$route.query.type;
    },
    async getLearnContentTypes() {
      const response = await Learn.getLearnContentTypes();
      if (!response?.data?.Data) return;

      const { contentTypes } = response.data.Data;
      this.academyModules.forEach(module => {
        const hasParentType = contentTypes.some(type => type.parentTypeId === module.parentTypeId);

        if (hasParentType) {
          module.types.push(
            ...contentTypes.filter(type => type.parentTypeId === module.parentTypeId),
          );
        }
      });
    },
    goToAcademy(page) {
      const url =
        page.parentTypeId == 26
          ? {
              name: RoutesLearn.DigitalLiteracy.name,
              params: page,
            }
          : {
              path: `${RoutesRoot.Secure.path}/${RoutesSecure.Entrepreneurship.path}`,
              query: { parentId: page.parentTypeId, id: page.contentTypeId },
            };
      this.$router.push(url);
    },
    infoCardBgColor(type, index) {
      return index === 0 ? 'yellow' : index === 1 ? 'blue' : 'green';
    },
    infoCardBgImage(type) {
      return {
        backgroundImage:
          type.parentTypeId == 452
            ? `url(${require(`@/assets/bg/entrepreneurship-${type.contentTypeId}.png`)})`
            : '',
      };
    },
  },
};
</script>

<style scoped lang="scss">
.header-wrapper {
  width: 100%;
  margin-top: palette-space-level(50);
  padding-left: palette-space-level('20');
  .level-card-wrapper {
    display: flex;

    &-item {
      width: 236px;
      height: 240px;
      padding: palette-space-level('20');
      margin: palette-space-level('20') palette-space-level('20') palette-space-level('60') 0;
      color: palette-color-level('white', '100');
      bottom: 0;
    }
  }
}

.calculator-wrapper {
  padding-left: palette-space-level('20');

  .calc-header {
    padding-bottom: palette-space-level(30);
  }

  .calc-link-items {
    width: calc(50% - 20px);
    display: inline-block;
    &:nth-child(even) {
      margin-right: 20px;
    }

    .button-link {
      border-radius: 4px;
      border: solid 1px palette-color-level('grey', '10');
      padding: palette-space-level(25) palette-space-level(20);
      margin-bottom: palette-space-level(10);
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
      display: flex;
      align-items: center;
    }
  }
}
</style>
